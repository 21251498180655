<template>
  <div class="w-full sm:w-8/12">
    <h2 class="text-lg font-bold mr-3 mt-8 mb-5">Rewards & Cashbacks</h2>

    <div class="flex justify-between mt-10">
      <!-- <div class="flex flex-row">
        <div
          class="w-48px h-48px rounded-full flex items-center justify-center bg-purple-600 bg-opacity-85"
        >
          <ion-icon name="ticket-outline" class="text-2xl text-purple-600 z-50" />
        </div>
        <div class="flex flex-col ml-4 mt-1">
          <span class="text-12 font-normal">CredPal Points</span>
          <span class="text-18 font-semibold">102.5</span>
          <button class="px-4 py-2 bg-purple-600 text-center text-xs text-white rounded-sm mt-2">Redeem Points</button>
        </div>
      </div> -->

      <div class="flex flex-row">
        <div
          class="w-48px h-48px rounded-full flex justify-center items-center bg-blue-500 bg-opacity-85"
        >
          <ion-icon name="cash-outline" class="text-2xl text-blue-500 z-50" />
        </div>
        <div class="flex flex-col ml-4 mt-1">
          <span class="text-12 font-normal">Statements Credits</span>
          <sm-loader v-if="walletResource.loading" />
          <span class="text-18 font-semibold">{{
            walletBalance | formatAmount
          }}</span>
          <button
            class="px-4 py-2 bg-blue-500 text-center text-xs text-white rounded-sm mt-2"
            :disabled="disableWallet"
            @click="openConfirmModal"
          >
            Redeem Credits
          </button>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <h2 class="text-lg font-bold mr-3 mt-8 mb-5">Earning History</h2>

      <tabs v-model="tab" :tabs="tabs" class="mb-6" />

      <component :is="tab" />
    </div>

    <div class="mt-12">
      <h2 class="text-lg font-bold mr-3 mt-8 mb-5">Redemption History</h2>
      <redemption ref="redemption"></redemption>
    </div>

    <modal
      className="w-11/12 sm:w-4/12 xl:w-3/12 flex flex-col"
      ref="confirmRedeemWallet"
      :key="componentKey"
    >
      <template v-if="getFormError(walletResource)">
        <div class="alert alert-red-soft mb-2">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(walletResource) }}</span>
        </div>
      </template>
      <h4 class="text-center text-18 leading-relaxed">
        You are about to use your credits to repay your credit card balance
      </h4>
      <p class="text-center text-14 mt-2">
        Are you sure you want to proceed?
      </p>
      <div class="mt-5 flex justify-center items-center">
        <button
          type="button"
          class="button bg-blue-500 hover:bg-blue-600 text-white mb-3 px-14"
          :disabled="disableWallet || loading"
          @click.prevent="redeemWallet"
        >
          Yes, Redeem
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  components: {
    Cashbacks: () => import("../transactions/Cashback"),
    Purchases: () => import("../transactions/Purchases"),
    Redemption: () => import("../transactions/WalletHistory"),
  },
  data() {
    return {
      tab: "cashbacks",
      loading: false,
      walletResource: this.$options.resource([]),
      componentKey: 0
    };
  },
  computed: {
    tabs() {
      return [{ title: "Cashbacks", name: "cashbacks" }];
    },
    walletBalance() {
      return this.walletResource?.data?.balance || 0;
    },
    disableWallet() {
      return this.walletBalance <= 0;
    },
  },
  mounted() {
    this.getWalletBalance();
  },
  methods: {
    openConfirmModal() {
      this.walletResource.error = ''
      this.$refs.confirmRedeemWallet.open()
    },
    async redeemWallet() {
      this.loading = true;
      await this.sendRequest("personal.wallet.redeemWallet", {
        success: async () => {
          await this.$refs.redemption.getWalletTransactions();
          await this.getWalletBalance();
          this.$refs.confirmRedeemWallet.close();
        },
        error: (error) => (this.walletResource.error = error),
      });
      this.loading = false;
    },
    async getWalletBalance() {
      this.walletResource.loading = true;
      await this.sendRequest("personal.wallet.walletBalance", {
        success: (response) => (this.walletResource.data = response.data.data),
        error: (error) => console.log(error),
      });
      this.walletResource.loading = false;
    },
  },
};
</script>
